:root {
  --lightgray: #d9d9d9;
  --gray: #716d6d;
  --orange: #f48915;
  --darkgrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.79%);
  --appColor: #3c3f45;
  box-sizing: border-box;
}
.App {
  background-image: url(./images/c.png);
  background-repeat: no-repeat;
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ddd;
}
.btn {
  background-color: #ddd;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.btn:hover {
  cursor: pointer;
}
.blur {
  background: rgba(253, 120, 43, 0.69);
  position: absolute;
  border-radius: 50%;
  filter: blur(150px);
  z-index: -9;
}
